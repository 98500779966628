import { useState, useEffect} from 'react'
import { Marker, useMap } from 'react-map-gl';
import data from './data.json';

export default function Markers({viewImage, handleZoomOut, trigger}) {
  const [hoverMarker, sethoverMarker]= useState([]);
  const [activePlace, setActivePlace]= useState(null);
  const {current: map} = useMap();

  const handlePlaceChange = (marker) => {
    setActivePlace(marker.index);
    map.flyTo({
      center: [marker.lng, marker.lat],
      zoom: marker.zoom,
      speed: 1
    });
  }
  
  map.on('zoom', () => {
    if (map.getZoom() > 4) {
      handleZoomOut(true);
    } else {
      handleZoomOut(false);
    }
  });

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const loc = query.get('loc')
    if (loc != null) {
      data.places.map((place) => {
        place.images.map((marker) => {
          if(marker.src == loc) {
            setActivePlace(place.index);
            map.jumpTo({ center: [marker.lng, marker.lat], zoom: 10});
          }
        } )
      })
    }
  },[])

  useEffect(() => {
    if (trigger) {
      setActivePlace(null);
    }
  }, [trigger]);

  return (
    <>
      {data.places.map((marker, index) => {
        if (activePlace != marker.index) {
          return(
            <Marker
            longitude={marker.lng}
            latitude={marker.lat}
            anchor="bottom"
            key={'marker-' + marker.index}
            onZ
            style={{ zIndex: hoverMarker === index ? 3 : "unset" }}
            >
            <div className="placeMarkerStyle"
              onMouseEnter={() => sethoverMarker(index)}
              onMouseLeave={() => sethoverMarker(null)}
              >
              <div className="placeMarkerStyleTop" onClick={() => {handlePlaceChange(marker)}}>
                  <b>{marker.name}</b>
                  <div className="placeMarkerStyleMid">
                    <b className="name">{marker.images.length + " images"}</b>
                  </div>
              </div>
              <div className="placeMarkerStyleBottom" />
            </div>
          </Marker>
          )
        }
      })}
      {
      activePlace != null && data.places[activePlace].images.map((image, index) => {
        return(
          <Marker
          longitude={image.lng}
          latitude={image.lat}
          anchor="bottom"
          key={'marker-' + image.src}
          style={{ zIndex: hoverMarker === index + 1000 ? 3 : "unset" }}
          >
          <div className="imageMarkerStyle"
            onMouseEnter={() => sethoverMarker(index + 1000)}
            onMouseLeave={() => sethoverMarker(null)}
            >
            <div className="imageMarkerStyleTop" onClick={() => {viewImage(image)}}>
              <img src={require('./images-thumbnail/' + image.src + '_tn.jpg')} alt="" />
              <div className='imageMarkerModal'>
                <b>View</b>
              </div>
            </div>
            <div className="imageMarkerStyleBottom" />
          </div>
        </Marker>
        )
      })}
    </>
  );
}