import Image from './Image'

export default function FullScreenModal({image, viewImage}) {

  return (
    image && (<div className='fullScreenModal'>
      <div className='info'>
        <div><b>Latitude:</b> {image.lat}</div>
        <div><b>Longitude:</b> {image.lng}</div>
      </div>
      <div className='close' onClick={() => {viewImage(null)}}>
        <b>Close Image</b>
      </div>
      <Image src={require('./images-big/' + image.src + '-min.jpg')} alt="" />
    </div> )
  );
}