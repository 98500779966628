import React from 'react'
import { useState } from 'react';

export default function Image({src}) {
    const [loading, setLoading] = useState(true);
    return (
    <div className='imageContainer'>
      <img 
        src={src} 
        style={{display: loading?"none":"block"}} 
        onLoad={(e)=>{setLoading(false)}}>
      </img>
      <div className="spinner" style={{display: loading?"block":"none"}} >Loading</div>
    </div>
  )
}