import React, { useState, useEffect} from 'react';
import MapView from './MapView'
import Gallery from './Gallery'
import { BrowserRouter, Routes, Route, Link} from "react-router-dom";
import './App.css';
import './Navigation.css';
import './FullScreenModal.css';
import './Gallery.css';

function App() {
  
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Gallery />} />
          <Route path="/map" element={<MapView />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
