import React, { useState, useEffect, useRef } from 'react';
import Navigation from './Navigation';
import Select from 'react-select';
import { Link, useNavigate } from "react-router-dom";
import data from './data.json';


function Gallery() {

  const [categories, setCategories] = useState([]);
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);
  let navigate = useNavigate();


  useEffect(() => {
    let catData = [];

    Promise.all(Array.from(document.querySelectorAll("img")).filter(img => !img.complete).map(img => new Promise(resolve => { img.onload = img.onerror = resolve; }))).then(() => {
      console.log('images finished loading');
      setImagesLoaded(true);
      return;
    });

    data.places.map((place) => {
      catData.push({label: place.name, value: place.name});
    });
    setCategories(catData);
  }, []);

  const handleSelectedCategories = (e) => {
    let array = [];
    e.map((cat) => {
      array.push(cat.value);
    })
    setSelectedCategories(array);
  }

  const Loader = () => {
    return (
      <div className='load-container'>
        <img src={require('./images/loading.gif')} alt="loading..." />
      </div>
    )
  }

  const arrayDataItems = data.places.map((place) => {
    let show = selectedCategories.includes(place.name);
    if (selectedCategories.length <= 0) show = true;

    return (
      <>
        { place.images.map((image, index) => {
          console.log(selectedCategories);
          if (window.innerWidth < 600) {
            return <img 
              src={require('./images-thumbnail/' + image.src + "_tn.jpg")} 
              key={index} 
              className={`gallery-item-mobile lazy-image ${show ? "show" : ""} ${imagesLoaded ? "loaded" : "not-loaded"}`} 
              onClick={() => {navigate("/map?loc=" + image.src)}}/>
          } else {

          }
          return (
            <div key={index} className={`gallery-item ${show ? "show" : ""} ${imagesLoaded ? "loaded" : ""}`}>
              <Link to={"/map?loc=" + image.src} > 
                <img 
                  src={require('./images-thumbnail/' + image.src + "_tn.jpg")} 
                  className={`lazy-image ${imagesLoaded ? "loaded" : ""}`}
                  alt="" />
                <div className='imageModal'>
                  <b>View location</b>
                </div>
              </Link>
            </div>
          );
        })}
      </>
    )
  });

  return (
    <div className='gallery'>
      <Navigation />
      <div className='galleryContainer'>
        <Select
          isMulti
          name="images"
          options={categories}
          className="basic-multi-select"
          classNamePrefix="select"
          placeholder="Filter Places"
          onChange={(e) => handleSelectedCategories(e)}
        />
        {!imagesLoaded && <img className="loader" src={require('./images/loading.gif')} fetchpriority="high" alt="loading..." />}
        <div className='image-gallery'>
          {arrayDataItems}
        </div>
      </div>
    </div>
  );
}

export default Gallery;
