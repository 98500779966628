import { Map, useMap } from "react-map-gl";
import React, { useState, useEffect, useRef } from "react";
import Markers from "./Markers";
import FullScreenModal from "./FullScreenModal";
import Navigation from "./Navigation";

const mapboxApiKey = process.env.REACT_APP_MAPBOX_API_KEY;
const jsonBinApiKey = process.env.REACT_APP_JSONBIN_API_KEY;

function MapView() {
	const screenWidth = window.innerWidth < 600 ? 1.8 : 1.8;
	const [fullScreenImage, setFullScreenImage] = useState(null);
	//const [showMap, setShowMap] = useState(false);
	const [showZoomOut, setShowZoomOut] = useState(false);
	//Trigger marker component on click of button
	const [trigger, setTrigger] = useState(0);
	const mapRef = useRef(null);

	const viewImage = (image) => {
		console.log(image);
		setFullScreenImage(image);
	};

	const handleZoomOut = (bool) => {
		setShowZoomOut(bool);
	};

	const resetGlobe = () => {
		setTrigger(trigger + 1);
		mapRef.current?.flyTo({
			// center: [14, 43.5],
			zoom: screenWidth,
			speed: 1,
		});
	};

	// useEffect(() => {
	//   fetch('https://api.jsonbin.io/v3/b/648b12a29d312622a3700a79', {headers: { 'X-Master-key': '$2b$10$' + jsonBinApiKey }})
	//     .then(response => response.json())
	//     .then(data => {

	//       if (data != null) {
	//         if (data.record != null) {
	//           const date = new Date();
	//           data.record.count++

	//           if(data.record.month != date.getMonth()) {
	//             data.record.month = date.getMonth();
	//             data.record.count = 1;
	//           }

	//           const requestOptions = {
	//             method: 'PUT',
	//             headers: { 'Content-Type': 'application/json', 'X-Master-key': '$2b$10$' + jsonBinApiKey },
	//             body: JSON.stringify(data.record)
	//           };

	//           fetch('https://api.jsonbin.io/v3/b/648b12a29d312622a3700a79', requestOptions)
	//               .then(response => response.json())
	//               .then(data => {
	//                 if (data.record.count < 8000) {
	//                   setShowMap(true);
	//                 }
	//               });
	//         }
	//       }

	//     })
	//     .catch(error => console.error(error));
	// }, []);

	return (
		<div className="mapview">
			<FullScreenModal image={fullScreenImage} viewImage={viewImage} />
			<Navigation />
			<Map
				id="map"
				ref={mapRef}
				mapboxAccessToken={mapboxApiKey}
				mapStyle="mapbox://styles/weslylodewijks/cleehorys000m01oa0jw5jhx9"
				projection="globe"
				initialViewState={{
					longitude: 14,
					latitude: 43.5,
					zoom: screenWidth,
				}}
			>
				<Markers
					viewImage={viewImage}
					handleZoomOut={handleZoomOut}
					trigger={trigger}
				/>
			</Map>
			{showZoomOut && (
				<div className="resetGlobe" onClick={() => resetGlobe()}>
					Zoom out
				</div>
			)}
		</div>
	);
}

export default MapView;
