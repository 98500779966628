import React from 'react';
import { Link, useLocation } from "react-router-dom";

function Navigation() {

  const pathname = useLocation().pathname

  return (
    <div className='navigation'>
      <Link className={`${pathname === '/' ? "active" : ""}`} to="/" >  Gallery  </Link>
      <Link className={`${pathname === '/map' ? "active" : ""}`} to="/map" >  Map  </Link>
    </div>
  );
}

export default Navigation;
